body {
    font-family: $font;
    font-size: $font-size;
    color: color('gray', 'dark');
}

.main-container {
    @include outer-container;

    .container, .main {
        @include span-columns(12, block-collapse);
        padding: 0 $gapp;
    }

    &.col2-left-layout,
    &.col2-right-layout {

        .col-left,
        .col-right {
            @include span-columns(2.8);
        }

        .col-main {
            @include span-columns(9.2);
            margin-right: 0;
        }

    }

    &.col3-layout {

        .col-left,
        .col-right {
            @include span-columns(3);
        }

        .col-main {
            @include span-columns(6);
        }

    }

}

a {
    text-decoration: none;
    color: color('gray', 'dark');
    @include transition(color, 0.2s);

    &:hover {
        color: color('blue', 'light');
    }

}

table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
}

.no-display {
    display: none !important;
}

.hide {
    display: none;
}

.loader {
    background: url('../images/loader.gif') no-repeat center center;
    display: block;
    height: 50px;
    width: 50px;
    margin: 0 auto;    
}

.qty {
    @include span-columns(12);

    span, input {
        display: inline-block;
        text-align: center;
        vertical-align: middle;
    }

    span {
        height: 35px;
        width: 35px;
        line-height: 32px;
        font-size: 30px;
        font-weight: bold;
        cursor: pointer;
        @include border-radius(50%, 50%, 50%, 50%);

        &.qty-left {
            color: color('blue');
            background: color('gray', 'light');
        }

        &.qty-right {
            color: color('white');
            background: color('blue');
        }

    }

    input {
        width: 65px;
        margin: 0 10px;
        font-size: 25px;
        color: color('gray');
        padding: 5px 10px;
    }

    &.dark {

        .qty-left {
            background: rgba(color('gray'), 0.2);
        }

    }

}