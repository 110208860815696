@font-face {
    font-family: 'Myriad Pro';
    src:    url('../fonts/MyriadPro-Regular.eot');
    src:    url('../fonts/MyriadPro-Regular.eot?tfqnd3#iefix') format('embedded-opentype'),
        url('../fonts/MyriadPro-Regular.ttf') format('truetype'),
        url('../fonts/MyriadPro-Regular.woff') format('woff'),
        url('../fonts/MyriadPro-Regular.svg#Myriad Pro') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Myriad Pro';
    src:    url('../fonts/MyriadPro-Bold.eot');
    src:    url('../fonts/MyriadPro-Bold.eot?tfqnd3#iefix') format('embedded-opentype'),
        url('../fonts/MyriadPro-Bold.ttf') format('truetype'),
        url('../fonts/MyriadPro-Bold.woff') format('woff'),
        url('../fonts/MyriadPro-Bold.svg#Myriad Pro') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue';
    src:    url('../fonts/BebasNeue-Regular.eot');
    src:    url('../fonts/BebasNeue-Regular.eot?tfqnd3#iefix') format('embedded-opentype'),
        url('../fonts/BebasNeue-Regular.ttf') format('truetype'),
        url('../fonts/BebasNeue-Regular.woff') format('woff'),
        url('../fonts/BebasNeue-Regular.svg#Myriad Pro') format('svg');
    font-weight: bold;
    font-style: normal;
}