.post-wrapper {
	@include span-columns(12);
	margin: 0 0 50px 0;

	a {
		font-weight: bold;
		color: color('blue', 'light');
	}

	h2, h1 {
		color: color('blue', 'light');
		font-weight: bold;
		display: block;
		font-size: 24px;
		text-transform: uppercase;
	}

	h3 {
		display: block;
		padding-top: 5px;
		font-size: 14px;
	}

	.std {
		@include span-columns(12);
		margin: 20px 0;
	    font-size: 16px;
	    line-height: 20px;	
	    text-align: left;

	    img {
	    	margin-bottom: 20px;
	    }	

	    .aw-blog-read-more {
	    	@include span-columns(12);
	    	margin: 10px 0 0 0;
	    }

	}

	.tags {
		display: inline-block;
		margin-bottom: 5px;

		li {
			display: inline-block;
		}

	}

	.poster {
		display: inline-block;
	}

}
