.checkout-onepage-success {

	.main-container {
    	@include outer-container(100%);
    	text-align: center;
    	
    	.container, .main {
    		@include span-columns(12);
    		padding: 0;
    	}

    	.align-container {
    		@include outer-container();
    		padding: 0 $gapp;
		}
		
		.success-billet-btn {
			display: block;
			width: 80%;
			margin: 0 auto;
			position: relative;

			button {
				background-color: color('green');
				position: absolute;
				font-size: 18px;
				height: 60px;
				top: -30px;
				padding: 13px 30px;
				margin: 0 auto;
				left: 0;
				right: 0;
				width: 40%;
			}
		}
    }

    .page-title {
    	@include span-columns(12);
    	margin: 60px 0;

	    h1 {
	    	color: color('blue');
	    }

	    p {
	    	font-size: 16px;
	    }

    }

    .box-info {
    	background: color('gray', 'light');
    	padding: 90px 0;

    	h2 {
    		color: color('blue');
    		font-weight: bold;
    		text-transform: uppercase;
    		font-size: 20px;
    		padding-bottom: 30px;

    		a {
    			color: color('blue');
    		}

    	}

    	p {
    		font-size: 16px;
    		line-height: 24px;
    	}

    	.buttons-set {
    		width: 80%;
    		display: block;
    		margin: 40px auto 0 auto;

    		.button {
    			width: 40%;
					display: inline-block;
					background-color: color('blue');

    			&.blue {
    				margin-right: 5%;
    			}

    		}

    	}

    }

}