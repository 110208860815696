.cms-institutional {

	.page-title {
		@extend .no-display;
	}
	
	.std {
		@include span-columns(12);
		line-height: 22px;
		margin: 0 0 50px 0;

		table {

			td {
				padding-bottom: 30px;
			}

		}

	}

}

.cms-menu {
	@include span-columns(12);
	background: rgba(color('white'), 0.5);
	border-bottom: 4px solid darken(color('gray', 'light'), 4%);

	ul {
		@include outer-container();

		li {
			@include span-columns(3, block-collapse);
			position: relative;
			padding: 30px 0 26px 50px;
			font-size: 16px;
			text-transform: uppercase;
			text-align: center;
			background: transparent;
			border-right: 1px solid darken(color('gray', 'light'), 4%);

			&:after {
				content: '';
				position: absolute;
				left: 0;
				bottom: -4px;
				width: 100%;
				height: 4px;
			}

			a {
				color: color('gray');
			}

			&.active {
				background: color('white');
				color: color('blue');

				&:after {
					background: color('blue');
				}					

			}

			span {
			    position: absolute;
			    top: 22px;
			    font-size: 30px;
			    margin-left: -40px;
			}

			&.category {
				background: color('green');

				&:after {
					background: color('green');
				}

				a {
					color: color('white');
				}

			}

		}

	}

}

.cms-sidebar {

	.block-title {
		text-transform: uppercase;
		padding: 26px 0;
		position: relative;
		border: 1px solid color('gray', 'light');
		border-left: 0;

		h2 {
			font-size: 18px;
			font-weight: bold;			
		}

		&:before,
		&:after {
			content: '';
			position: absolute;
			left: -1000%;
			height: 100%;
			width: 1000%;
		}

		&:before {
			border-top: 1px solid color('gray', 'light');
			top: -1px;
		}

		&:after {
			bottom: -1px;
			border-bottom: 1px solid color('gray', 'light');
		}

	}

	.block-content {
		margin-bottom: 100px;

		li {
			position: relative;
			border: 1px solid color('gray', 'light');
			border-left: 0;
			font-size: 16px;
			font-weight: bold;
			text-transform: uppercase;
			padding: 20px 0;
			border-top: 0;

			a {
				color: color('blue');
			}

			&:after {
				content: '';
				position: absolute;
				left: -1000%;
				height: 100%;
				width: 1000%;
			}

			&:after {
				border-bottom: 1px solid color('gray', 'light');
				top: 1px;
			}

			&.current {
				background: color('blue', 'light');
				color: color('white');
				border-color: color('blue', 'light');

				a {
					color: color('white');
				}

				&:after {
					background: color('blue', 'light');
					border-color: color('blue', 'light');
				    top: 0;
				    height: 102%;
				}

			}

		}

	}

}