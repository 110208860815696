.content-home {
	@include outer-container;
	padding: 0 $gapp;

	.tabs-home {
		@include span-columns(12);
		position: relative;

		.tabs-block {
			@include span-columns(12);
			margin: 20px 0 0 0;
			padding: 15px 0;
			text-transform: uppercase;
			color: color('blue', 'light');
			border-bottom: 1px solid rgba(color('gray'), 0.2);

			h2 {
				font-size: 24px;					
			}

			li {
				@include span-columns(12);
				position: relative;
			}

		}

		.container-tabs {
			@include span-columns(12);
			padding: 20px 0 0 0;

			.content-tab {
				@include span-columns(12);
			}
			
		}

		.loader {
			margin-bottom: 20px;
		}

	}

}
