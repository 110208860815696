.customer-account-create {

	.page-title {
		@include span-columns(12);
		text-align: center;

		h1 {
			color: color('blue');
			padding-bottom: 5px;
		}

		p {
			font-size: 16px;
		}

	}

	h2 {
		@include span-columns(12);
		margin: 0 0 26px 0;
		color: color('blue');	
		text-transform: uppercase;
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 1px;
	}

	.col-1, .col-2 {
		@include span-columns(6);
	}

	.col-1 {
		padding-right: 100px;
		border-right: 2px solid color('blue');				
	}

	.col-2 {
		padding-left: 80px;
		margin-right: 0;
	}

	.buttons-set {
		@include span-columns(12);
		margin: 60px 0 100px 0;

		.button {
			@include span-columns(3);
		}

	}

	.phone-layout {

		border: 1px solid color('gray');
		height: 49px;

		select {

			@include span-columns(4);
			margin-bottom: 20px;
			border: none;
			background: color('gray', 'light');
			width: 30%;
			padding: 13px 8px;
		}

		input {

			@include span-columns(8);
			margin-bottom: 20px;
			border: none;
			padding-bottom: 11px;
		}
	}
}