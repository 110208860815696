.customer-account {

	.back-link {
		@extend .no-display;
	}

	.sidebar {
		@import "components/account/sidebar.scss";
	}

	.page-title {
		@include span-columns(12);
		padding: 30px;
		border-bottom: 4px solid color('gray', 'light');

		h1 {
			font-size: 18px;
			color: color('blue');
			padding-bottom: 0;
		}

	}

	.content {
		@include span-columns(12);
		padding: 20px 0 20px 30px;
		color: color('black');

		h2 {
			font-size: 16px;
			text-transform: uppercase;
			color: color('blue');
			font-weight: bold;
			letter-spacing: 2px;
			padding-top: 10px;
			padding-bottom: 30px;
		}

		h3 {
			font-size: 16px;
			text-transform: uppercase;
			font-weight: bold;
			letter-spacing: 2px;
			padding-bottom: 30px;			
		}

		.col-1, .col-2 {
			@include span-columns(6);
		}

		.col-1 { 

			.box {
				padding-right: 50px;
				border-right: 2px solid color('blue');				
			}

		}

		.col-2 { 
			margin-right: 0;

			.box {
				padding-left: 30px;
			}

		}

		.box {
			@include span-columns(12);
			margin: 0 0 80px 0;

			.box-title {
				@include span-columns(12);

				h2 {
					@include span-columns(8);
					padding-top: 0;
					padding-bottom: 0;					
				}

				a {
					@include span-columns(4);
					font-size: 16px;
					color: color('blue');
					font-weight: bold;
					text-decoration: underline;					
				}

			}

			.box-content {
				@include span-columns(12);
				margin-top: 20px;
				font-size: 16px;
				line-height: 20px;
			}

		}

		.phone-layout {

			border: 1px solid color('gray');
			height: 46px;

			select {

				@include span-columns(4);
				margin-bottom: 20px;
				border: none;
				background: color('gray', 'light');
				width: 27%;
				padding: 13px 8px;
			}

			input {

				@include span-columns(8);
				margin-bottom: 20px;
				border: none;
				padding-bottom: 11px;
			}
		}
	}

	address {

		strong {
			display: block;
		}

	}

	@import "components/account/dashboard.scss";
	@import "components/account/information.scss";
	@import "components/account/address.scss";
	@import "components/account/orders.scss";
}